import React, { useState } from 'react';

import Button from '@rotaryintl/harris-button';
import IconFont from '@rotaryintl/harris-icon-font';

import DistrictCardClubs from '@presenters/web/components/OfficialDirectory/District/DistrictCardClubs';

import { useTranslation } from '@external/react-i18next';

import { DistrictCardResultsProps } from '@typings/official-directory';

const DistrictCardResults: React.FC<DistrictCardResultsProps> = ({
  district,
  searchData,
  expandedCardId,
  onClick,
}) => {
  const { t } = useTranslation();
  const [showClubData, setShowClubData] = useState(false);
  const {
    id,
    districtNumber,
    legalDesc,
    zone,
    occupationTitle,
    name,
    address,
    phoneNumber,
    workNumber,
    emailId,
    spousePartner,
    classification,
  } = district;
  const isSingleResult = searchData.length === 1;
  return (
    <div key={id} className="shadow-xl rounded-xlg my-6 p-8 w-full">
      <div>
        <h4 className="text-dblue text-lg desktop:text-lg-24 font-semibold leading-md-30 desktop:leading-9">
          {t(
            'official.directory.district-card-district-number',
            'District {{districtNumber}}',
            { districtNumber }
          )}
        </h4>
        <h5 className="font-semibold leading-7 text-lg-18">
          {legalDesc}; {t('official.directory.district-card-zone', 'Zone')}{' '}
          {zone}
        </h5>
        <h6 className="font-semibold leading-md text-md pt-4 pb-2.5 m-0 normal-case">
          {occupationTitle}
        </h6>
        <p className="font-normal text-md leading-md pb-1 m-0">{name}</p>
        <p className="font-normal text-md leading-md m-0">{address}</p>
        <h6 className="font-semibold leading-md text-md pt-2 pb-2.5 mt-3 mb-0 normal-case">
          {t('official.directory.district-card-contact', 'Contact')}
        </h6>
        <div className="flex flex-row gap-1.5 items-center">
          <IconFont
            color="#0c3d7d"
            size="16"
            className="bg-dblue rounded-full club-icon-font"
            type="fas-phone"
          />
          <p className="font-normal text-md leading-md py-1 my-0">
            {phoneNumber}
          </p>
        </div>
        {isSingleResult || expandedCardId === id ? (
          <div className="flex flex-row gap-1.5 items-center">
            <IconFont
              color="#0c3d7d"
              size="16"
              className="bg-dblue rounded-full club-icon-font"
              type="fas-fax"
            />
            <p className="font-normal text-md leading-md py-1 my-0">
              {workNumber}
            </p>
          </div>
        ) : null}
        <div className="flex flex-row gap-1.5 items-center">
          <IconFont
            color="#0c3d7d"
            size="16"
            className="bg-dblue rounded-full club-icon-font"
            type="fas-envelope"
          />
          <p className="font-semibold text-dblue text-md leading-md py-1 pb-2">
            {emailId}
          </p>
        </div>

        {isSingleResult || expandedCardId === id ? (
          <>
            <h6 className="font-semibold leading-md text-md mb-2 mt-6 normal-case">
              {t('official.directory.district-card-spouse', 'Spouse/Partner')}
            </h6>
            <p className="font-normal leading-md text-md">{spousePartner}</p>
            <h6 className="font-semibold leading-md text-md mt-6 normal-case">
              {t(
                'official.directory.district-card-classification',
                'Classification'
              )}
            </h6>
            <p className="font-normal leading-md text-md">{classification}</p>
          </>
        ) : null}
      </div>
      <div>
        {isSingleResult ? (
          <Button
            clickHandler={() => {
              setShowClubData(!showClubData);
            }}
            icon={showClubData ? 'fas-angle-up' : 'fas-angle-down'}
            size="md"
            variant="text"
            className="mt-6"
            isIconLeftAligned={false}
            style={{ color: '#323C44' }}
          >
            {showClubData
              ? t('official.directory.district-card-hide-clubs', 'Hide clubs')
              : t('official.directory.district-card-show-clubs', 'Show clubs')}
          </Button>
        ) : (
          <Button
            clickHandler={() => onClick(id)}
            icon={expandedCardId === id ? 'fas-angle-up' : 'fas-angle-down'}
            size="md"
            variant="text"
            className="mt-6"
            isIconLeftAligned={false}
            style={{ color: '#323C44' }}
          >
            {expandedCardId === id
              ? t('official.directory.district-card-view-less', 'View less')
              : t('official.directory.district-card-view-more', 'View more')}
          </Button>
        )}
      </div>
      {isSingleResult && showClubData && (
        <div className="my-6 border-t-2 border-solid border-dblue">
          <DistrictCardClubs searchParams={districtNumber} />
        </div>
      )}
    </div>
  );
};

export default DistrictCardResults;
