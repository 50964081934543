import { gql } from '@apollo/client';

export const getOfficialDirectoryClubSearchByIdQuery = gql`
  query officialDirectoryClubSearchById(
    $input: OfficialDirectoryClubSearchByIdInput!
  ) {
    officialDirectoryClubSearchById(input: $input) {
      response {
        numFound
        start
        maxScore
        numFoundExact
        docs {
          SecyLastName
          SecyCstKey
          ClubCharterDate
          ClubMeetingCity
          PresPrimaryCity
          PresPrimaryEmail
          ClubPrimaryCountry
          ExecSecyLastName
          ExecSecyCstKey
          ExecSecyPrimaryEmail
          SecyPrimaryCountry
          ClubMeetingName
          SponsoredClubs
          ClubPrimaryEmail
          ClubPrimaryPhone
          ClubPrimaryPhoneCountryCode
          SecyPrimaryCity
          ClubName
          PresFirstName
          SecyPrimaryEmail
          ClubId
          PresLastName
          id
          ClubKey
          PresCstKey
          SecyPrimaryAddrLine2
          SecyPrimaryPhoneCountryCode
          SecyPrimaryAddrLine1
          ExecSecyPrimaryAddrLine1
          PresPrimaryPhone
          SecyPrimaryAddrline3
          ExecSecyMemberId
          ExecSecyPrimaryAddrLine2
          ExecSecyPrimaryCountry
          ClubMeetingDay
          ClubMeetingAddrLine1
          ClubMeetingAddrLine2
          ClubMeetingAddrLine3
          SecyMemberId
          ExecSecyPrimaryPhone
          ClubMeetingState
          SecyPrimaryPhone
          ClubMeetingPostalCode
          PresPrimaryAddrLine1
          SecyPrimaryPostalCode
          PresPrimaryPhoneCountryCode
          PresPrimaryState
          ExecSecyPrimaryPhoneCountryCode
          ExecSecyPrimaryState
          MemberCount
          PresPrimaryCountry
          PresPrimaryPostalCode
          PresMemberId
          ClubMeetingTime
          SecyFirstName
          ExecSecyPrimaryPostalCode
          lubPrimaryState
          ExecSecyFirstName
          ClubPrimaryWebsite
          SecyPrimaryState
          DistrictId
          ExecSecyPrimaryCity
          ClubMeetingCountry
        }
      }
      responseHeader {
        status
        QTime
        params {
          q
          rows
        }
      }
    }
  }
`;
