import { gql } from '@apollo/client';

export const getOfficialDirectoryDistrictSearchByIdQuery = gql`
  query officialDirectoryDistrictSearchById(
    $input: OfficialDirectoryDistrictSearchByIdInput!
  ) {
    officialDirectoryDistrictSearchById(input: $input) {
      response {
        numFound
        start
        maxScore
        numFoundExact
        docs {
          DistRepKey
          DistRepRole
          DistRepAddressPostalCode
          DistRepPhoneNumber
          DistRepFaxNumber
          DistRepFaxCountryCode
          DistRepAddressCountry
          DistRepPhoneCountryCode
          FoundedDate
          DistRepSpouseMemberId
          DistRepAddressState
          id
          Key
          DistRepSpouseFirstName
          DistRepAddressLine1
          DistRepAddressLine2
          DistRepEmailAddress
          DistRepAddressLine3
          DistRepSpouseKey
          DistRepSpouseLastName
          DistRepFirstName
          DistRepEndDate
          DistRepClassification
          DistrictId
          Zone
          DistRepCallName
          DistRepAddressCity
          ActiveClubCount
          Name
          LegalDescription
          DistRepLastName
          DistRepStartDate
          DistRepPhoneCountry
          DistRepMemberId
        }
      }
      responseHeader {
        status
        QTime
        params {
          q
          rows
        }
      }
    }
  }
`;
