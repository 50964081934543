import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Alert from '@rotaryintl/harris-alert';
import Pagination from '@rotaryintl/harris-pagination';

import ClubsByCityResults from '@presenters/web/components/OfficialDirectory/Club/ClubsByCityResults';
import {
  CLUBS_CURRENT_PAGE,
  CLUBS_PAGE_SIZE,
  CLUBS_PAGINATION_VARIANT,
} from '@presenters/web/components/OfficialDirectory/constants';
import LoadingComponent from '@presenters/web/components/OfficialDirectory/Others/LoadingComponent';
import NoResultFound from '@presenters/web/components/OfficialDirectory/Others/NoResultFound';

import { getOfficialDirectoryClubSearchByCityQuery } from '@queries/getOfficialDirectoryClubSearchByCityQuery';

import { useTranslation } from '@external/react-i18next';

import { OfficialDirectoryClubSearchByCityDocs } from '@typings/graphql';
import {
  ClubByCity,
  ClubsByCityCardProps,
  LanguageCode,
} from '@typings/official-directory';

function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const ClubsByCityCard: FC<ClubsByCityCardProps> = ({ searchParams }) => {
  const { t, i18n } = useTranslation();

  const langCode = i18n.language.toLowerCase() as LanguageCode;

  // Result of search query.
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState<ClubByCity[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);

  const pageSize = CLUBS_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState<number>(CLUBS_CURRENT_PAGE); // Defaults to 1.

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalCount);

  // On search params change.
  useEffect(() => {
    // On search params reset.
    if (searchParams?.trim() === '') {
      setSearchData([]);
      setTotalCount(0);
      setCurrentPage(CLUBS_CURRENT_PAGE);
    } else {
      setLoading(true);
    }
  }, [searchParams]);

  // On page change.
  useEffect(() => {
    setLoading(true);
  }, [currentPage]);

  // Data fetching query.
  const { data } = useQuery(getOfficialDirectoryClubSearchByCityQuery, {
    variables: {
      input: {
        query: searchParams ? `ClubMeetingCity:${searchParams}*` : '',
        fl: '* ,score',
        start: String(startIndex),
        sort: 'score desc, ClubName asc',
        rows: String(pageSize),
        wt: 'json',
      },
    },
  });

  const prevSearchData = usePrevious(searchData);
  // Data Transformer
  useEffect(() => {
    if (data?.officialDirectoryClubSearchByCity?.response?.docs?.length) {
      const tempData = data?.officialDirectoryClubSearchByCity?.response?.docs?.map(
        (club: OfficialDirectoryClubSearchByCityDocs) => ({
          id: club.id,
          clubCity: club.ClubMeetingCity || '',
          clubState: club.ClubMeetingState || '',
          clubCountry: club.ClubMeetingCountry || '',
          districtNumber: club.DistrictId,
          clubSite: club.ClubPrimaryWebsite || '',
          address: `${
            club.ClubMeetingAddrLine1 ? club.ClubMeetingAddrLine1 : ''
          } ${
            club.ClubMeetingAddrLine2 ? `, ${club.ClubMeetingAddrLine2}` : ''
          } ${
            club.ClubMeetingAddrLine3 ? `, ${club.ClubMeetingAddrLine3}` : ''
          }`,
          meeting: `${club.ClubMeetingDay || ''}/${club.ClubMeetingTime || ''}`,
          contact: {
            phone: club.ClubPrimaryPhone
              ? (club.ClubPrimaryPhoneCountryCode
                  ? `+ ${club.ClubPrimaryPhoneCountryCode} `
                  : '') + club.ClubPrimaryPhone
              : '',
            email: club.ClubPrimaryEmail || '',
          },
          charterDate: club.ClubCharterDate,
          memberCount: club.MemberCount,
          sponsoredClubs: club.SponsoredClubs,
        })
      );

      if (prevSearchData !== tempData) {
        // Set data to state variable.
        setSearchData(tempData);
        setTotalCount(
          data?.officialDirectoryClubSearchByCity?.response?.numFound || 0
        );

        if (tempData.length > 0) {
          setExpandedCardId(tempData[0].id);
        }

        setLoading(false);
      } else {
        // TODO: Verify.
      }
    } else if (
      data &&
      !data?.officialDirectoryClubSearchByCity?.response?.docs?.length
    ) {
      setLoading(false);
    }
  }, [data]);

  const handleExpand = (id: string) => {
    setExpandedCardId(prevId => (prevId === id ? null : id));
  };

  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <LoadingComponent />;

  // TODO: Look again.
  if (!loading && !searchData?.length) {
    return <NoResultFound searchParams={searchParams} />;
  }

  return (
    <div className="my-3">
      <div className="py-2">
        <p className="text-xs">
          {t('official.directory.clubs-by-city-pagination-title-1', 'Showing')}{' '}
          <span className="font-bold">{startIndex + 1}</span>-
          <span className="font-bold">{endIndex}</span>{' '}
          {t('official.directory.clubs-by-city-pagination-title-2', 'of')}{' '}
          <span className="font-bold">{totalCount}</span>{' '}
          {t(
            'official.directory.clubs-by-city-pagination-title-3',
            'results for'
          )}{' '}
          {searchParams}
        </p>
      </div>
      <div className="my-0">
        <Alert variant="info" isDismissible={false}>
          {t(
            'official.directory.clubs-by-city-results-alert',
            `Please visit the club website or contact the club directly to confirm
          meeting details. Information submitted to Rotary International may not
          be reflected immediately in search results.`
          )}
        </Alert>
      </div>
      <div>
        {searchData?.length &&
          searchData.map(club => (
            <ClubsByCityResults
              key={club.id}
              club={club}
              expandedCardId={expandedCardId}
              onClick={handleExpand}
            />
          ))}
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          lang={langCode}
          onPageChange={onPageChange}
          pageSize={pageSize}
          siblingCount={CLUBS_CURRENT_PAGE}
          totalCount={totalCount || 0}
          variant={CLUBS_PAGINATION_VARIANT}
        />
      </div>
    </div>
  );
};

export default ClubsByCityCard;
