import React from 'react';

import Button from '@rotaryintl/harris-button';
import IconFont from '@rotaryintl/harris-icon-font';
import Link from '@rotaryintl/harris-link';

import { useTranslation } from '@external/react-i18next';

import { DistrictCardClubsResultsProps } from '@typings/official-directory';

const DistrictCardClubsResults: React.FC<DistrictCardClubsResultsProps> = ({
  district,
  expandedCardId,
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    id,
    clubName,
    clubSite,
    clubAddress,
    clubHours,
    contact,
    occupation,
    charterDate,
    memberCount,
    sponsoredClubs,
  } = district;
  return (
    <div key={id} className="p-6 m-0 border-b-2 border-solid border-gray-275">
      <h4 className="font-semibold text-lg-18 leading-lg">{clubName}</h4>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-browser"
        />
        <Link
          to={clubSite}
          target="_blank"
          className="text-azure text-md font-bold px-0"
        >
          {clubSite}
        </Link>
      </div>
      <p className="text-md">{clubAddress}</p>
      <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
        {t('official.directory.district-clubs-meeting-day', 'Meeting day/time')}
      </h6>
      <p className="text-md">{clubHours}</p>
      <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
        {t('official.directory.district-clubs-contact', 'Contact')}
      </h6>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-phone"
        />
        <p className="font-normal text-md leading-md py-1 my-0">
          {contact.phone}
        </p>
      </div>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-envelope"
        />
        <p className="font-normal text-md leading-sm py-1 my-0">
          {contact.email}
        </p>
      </div>
      {expandedCardId === id && (
        <>
          <div className="mt-2">
            {occupation.map(item => {
              const { id, name, title, address, email } = item;
              return (
                <div key={id} className="py-2.5">
                  <h6 className="font-semibold text-md pb-2 m-0 normal-case">
                    {title}
                  </h6>
                  <p className="text-md m-0 pb-2">{name}</p>
                  <p className="text-md m-0 pb-2">{address}</p>
                  <p className="text-md text-azure m-0 pb-2">{email}</p>
                </div>
              );
            })}
          </div>
          <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
            {t(
              'official.directory.district-clubs-character-date',
              'Charter Date'
            )}
          </h6>
          <p className="text-md">{charterDate}</p>
          <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
            {t(
              'official.directory.district-clubs-member-count',
              'Member Count'
            )}
          </h6>
          <p className="text-md">{memberCount}</p>
          <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
            {t(
              'official.directory.district-clubs-sponsored-clubs',
              'Sponsored Clubs'
            )}
          </h6>
          <p className="text-md">{sponsoredClubs}</p>
        </>
      )}
      <div>
        <Button
          clickHandler={() => onClick(id)}
          icon={expandedCardId === id ? 'fas-angle-up' : 'fas-angle-down'}
          size="md"
          variant="text"
          className="mt-6"
          isIconLeftAligned={false}
          style={{ color: '#323C44' }}
        >
          {expandedCardId === id
            ? t('official.directory.district-clubs-view-less', 'View less')
            : t('official.directory.district-clubs-view-more', 'View more')}
        </Button>
      </div>
    </div>
  );
};

export default DistrictCardClubsResults;
