import * as React from 'react';

import LoaderSpinner from '@rotaryintl/harris-loader-spinner';

const LoadingComponent: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '10em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span
        style={{
          background: 'white',
          display: 'inline-block',
        }}
      >
        <LoaderSpinner size="large" theme="light" />
      </span>
    </div>
  );
};
export default LoadingComponent;
