import { gql } from '@apollo/client';

export const getOfficialDirectoryClubSearchByCityQuery = gql`
  query officialDirectoryClubSearchByCity(
    $input: OfficialDirectoryClubSearchByCityInput!
  ) {
    officialDirectoryClubSearchByCity(input: $input) {
      response {
        numFound
        start
        maxScore
        numFoundExact
        docs {
          SecyLastName
          SecyCstKey
          ClubCharterDate
          ClubMeetingCity
          PresPrimaryCity
          SecyCallName
          PresPrimaryEmail
          AdditionalMeetingNotes
          ClubPrimaryCountry
          ExecSecyLastName
          ExecSecyCstKey
          ExecSecyPrimaryEmail
          ClubPrimaryPhone
          ClubPrimaryEmail
          SecyPrimaryCountry
          ClubMeetingName
          SponsoredClubs
          SecyPrimaryCity
          ClubName
          PresFirstName
          ExecSecyPrimaryFax
          ClubPrimaryPhoneCountryCode
          SecyPrimaryEmail
          ClubId
          PresLastName
          id
          ClubKey
          PresCstKey
          SecyPrimaryPhoneCountryCode
          SecyPrimaryAddrLine1
          ExecSecyPrimaryAddrLine1
          PresPrimaryPhone
          ExecSecyMemberId
          ExecSecyPrimaryCountry
          ClubMeetingDay
          ClubMeetingAddrLine1
          SecyMemberId
          ExecSecyPrimaryPhone
          ClubMeetingState
          SecyPrimaryPhone
          PresPrimaryAddrLine2
          ClubMeetingPostalCode
          PresPrimaryAddrLine1
          SecyPrimaryPostalCode
          PresPrimaryPhoneCountryCode
          PresPrimaryState
          ExecSecyPrimaryPhoneCountryCode
          ExecSecyPrimaryState
          MemberCount
          PresPrimaryCountry
          PresPrimaryPostalCode
          PresMemberId
          ClubMeetingTime
          PresCallName
          SecyFirstName
          ExecSecyPrimaryPostalCode
          ClubPrimaryState
          ExecSecyFirstName
          ClubMeetingAddrLine2
          ClubPrimaryWebsite
          ClubMeetingAddrLine3
          SecyPrimaryState
          DistrictId
          ExecSecyPrimaryCity
          ClubMeetingCountry
        }
      }
      responseHeader {
        status
        QTime
        params {
          q
          rows
        }
      }
    }
  }
`;
