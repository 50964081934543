import React from 'react';

import Button from '@rotaryintl/harris-button';
import IconFont from '@rotaryintl/harris-icon-font';
import Link from '@rotaryintl/harris-link';
import moment from 'moment';

import { useTranslation } from '@external/react-i18next';

import { ClubsByCityCardResultsProps } from '@typings/official-directory';

const ClubsByCityResults: React.FC<ClubsByCityCardResultsProps> = ({
  club,
  expandedCardId,
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    id,
    clubCity,
    clubState,
    clubCountry,
    districtNumber,
    clubSite,
    address,
    meeting,
    contact,
    charterDate,
    memberCount,
    sponsoredClubs,
  } = club;

  return (
    <div key={id} className="shadow-xl rounded-xlg p-8 my-6 w-full">
      <h4 className="font-semibold text-lg-24 leading-lg">{`${clubCity || ''}${
        clubState ? `, ${clubState}` : ''
      }; ${clubCountry}`}</h4>
      <h5 className="text-azure text-lg-18 font-semibold">
        {t(
          'official.directory.clubs-by-city-district-number',
          'District {{districtNumber}}',
          { districtNumber }
        )}
      </h5>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-browser"
        />
        <Link
          to={clubSite}
          target="_blank"
          className="text-azure text-lg-18 font-bold px-0"
        >
          {clubSite}
        </Link>
      </div>
      <p className="text-md">{address}</p>
      <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
        {t('official.directory.clubs-by-city-meeting', 'Meeting day/time')}
      </h6>
      <p className="text-md">{meeting}</p>
      <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
        {t('official.directory.clubs-by-city-contact', 'Contact')}
      </h6>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-phone"
        />
        <p className="font-normal text-md leading-md py-1 my-0">
          {contact?.phone}
        </p>
      </div>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-envelope"
        />
        <p className="font-normal text-md leading-md py-1 my-0">
          {contact.email}
        </p>
      </div>
      {expandedCardId === id && (
        <div>
          <h6 className="font-semibold leading-md text-md mb-2 mt-6 normal-case">
            {t('official.directory.clubs-by-city-charter-date', 'Charter Date')}
          </h6>
          <p className="text-md">
            {moment(charterDate, 'MM/DD/YYYY').format('DD-MMM-YYYY')}
          </p>
          <h6 className="font-semibold leading-md text-md mt-6 normal-case">
            {t('official.directory.clubs-by-city-member-count', 'Member Count')}
          </h6>
          <p className="text-md">{memberCount}</p>
          <h6 className="font-semibold leading-md text-md mt-6 normal-case">
            {t(
              'official.directory.clubs-by-city-sponsered-clubs',
              'Sponsered Clubs'
            )}
          </h6>
          <p className="text-md">{sponsoredClubs}</p>
        </div>
      )}
      <div>
        <Button
          clickHandler={() => onClick(id)}
          icon={expandedCardId === id ? 'fas-angle-up' : 'fas-angle-down'}
          size="md"
          variant="text"
          className="mt-4"
          isIconLeftAligned={false}
          style={{ color: '#323C44' }}
        >
          {expandedCardId === id
            ? t('official.directory.clubs-by-city-view-less', 'View less')
            : t('official.directory.clubs-by-city-view-more', 'View more')}
        </Button>
      </div>
    </div>
  );
};

export default ClubsByCityResults;
