import * as React from 'react';
import { useState } from 'react';

import Button from '@rotaryintl/harris-button';
import RadioField from '@rotaryintl/harris-form-basic/dist/RadioField/RadioField';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import { Form, Formik } from 'formik';

import ClubsByCityCard from '@presenters/web/components/OfficialDirectory/Club/ClubsByCityCard';
import ClubsByNameCard from '@presenters/web/components/OfficialDirectory/Club/ClubsByNameCard';

import '../styles.css';

import { useTranslation } from '@external/react-i18next';

const ClubSection: React.FC = () => {
  const { t } = useTranslation();
  const defaultSelectedField = t('official.directory.club-name', 'club name');
  const [searchParams, setSearchParams] = useState('');
  const [selectedField, setSelectedField] = useState(defaultSelectedField);

  return (
    <div className="m-0">
      <Formik
        initialValues={{
          radioField: defaultSelectedField,
          search: '',
        }}
        onSubmit={values => {
          const { search, radioField } = values;
          setSearchParams('');
          setSearchParams(search);
          setSelectedField(radioField);
        }}
        onReset={() => {
          setSearchParams('');
          setSelectedField(defaultSelectedField);
        }}
      >
        {({ values, submitForm, resetForm }) => {
          const { radioField, search } = values;
          return (
            <Form>
              <RadioField
                label={t(
                  'official.directory.club-radio-label',
                  'Choose club search type'
                )}
                name="radioField"
                options={[
                  {
                    label: t(
                      'official.directory.club-radio-option-label-1',
                      'Club name'
                    ),
                    value: t(
                      'official.directory.club-radio-option-value-1',
                      'club name'
                    ),
                  },
                  {
                    label: t(
                      'official.directory.club-radio-option-label-2',
                      'Club city'
                    ),
                    value: t(
                      'official.directory.club-radio-option-value-2',
                      'club city'
                    ),
                  },
                ]}
              />
              <div className="w-full desktop:w-3/5">
                <TextField
                  label={radioField === 'club name' ? 'Club name' : 'Club city'}
                  name="search"
                  placeholder={t(
                    'official.directory.club-search-place-holder',
                    'Search by {{radioField}}',
                    { radioField }
                  )}
                  icon="search"
                  type="search"
                  description={t(
                    'official.directory.club-search-description',
                    'Please include at least two characters. Use Latin characters only. To find an e-club, you must include part of the club name in your search. Typing "e-club" alone will not produce any results.'
                  )}
                />
              </div>
              <div className="flex flex-row gap-2 mobile-l:gap-4 pt-3 pb-2 justify-start">
                <Button clickHandler={submitForm} className="button-spacing">
                  {t('official.directory.club-search-button', 'Search')}
                </Button>
                {search && (
                  <Button clickHandler={resetForm} size="md" variant="text">
                    {t('official.directory.club-reset-button', 'Reset')}
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <div>
        {searchParams &&
          (selectedField === defaultSelectedField ? (
            <ClubsByNameCard searchParams={searchParams} />
          ) : (
            <ClubsByCityCard searchParams={searchParams} />
          ))}
      </div>
    </div>
  );
};
export default ClubSection;
